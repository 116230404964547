// 'use strict';
// imagesLoaded.makeJQueryPlugin($);
// import PreloadIt from 'utility/PreloadIt';
const imagesLoaded = require('imagesloaded');

const useCircleLoading = false;           // use js-circleLoading
const log = false;                        // debug
const color_progress  = '\u001b[33m';     // yellow
const color_update    = '\u001b[36m';     // cyan
const color_done      = '\u001b[35m';     // magenta
window.isPreloaded = false;               // reference in Loader.js


class Preload {

  constructor() {
    this.dom = {};
    this.dom.imgs = document.getElementsByTagName('img');
    this.dom.bar = document.getElementsByClassName('js-preload-bar')[0];
    this.dom.circle = document.getElementsByClassName('js-circleLoading')[0];
    this.dom.pie = document.getElementsByClassName('js-circleLoading__indicator__fill')[0];
    // this.preloadIt = new PreloadIt();
    this.origin = window.location.origin;
    this.timer = null;
    this.fps = 33;

    this.per = 0;                         // 読み込み経過パーセンテージ(0〜100)
    this.deg = 0;                         // 読み込み経過パーセンテージの角度(0〜360)
    this.current = 0;                     // 読み込み経過のカウント（全img要素）
    this.total = this.dom.imgs.length;    // 読み込み完了目標総数（全img要素）
    this.isImgsLoaded = false;            // img読み込み完了通知
    // this.isJsLoaded = false;              // js読み込み完了通知
    this.isJsLoaded = true;              // js読み込み完了通知
    this.init();
  }

  init() {
    this.fetchJs();
    this.fetchImgs();
    if (!this.timer) this.timer = setInterval(()=> {
      this.update();
    }, this.fps);
  }

  fetchJs() {
    // this.preloadIt.fetch([
    //   this.origin + '/assets/js/bundle.js'
    // ]).then((result)=> {
    //   this.isJsLoaded = true;
    // });
  }

  fetchImgs() {
    const imgsLoad = imagesLoaded('body', { background: true }, ()=> {
      this.isImgsLoaded = true;
    });
    imgsLoad.on('progress', (instance, image)=> {
      this.total = instance.images.length;
      this.current = instance.progressedCount;
      this.per = Math.floor((this.current / this.total) * 100);
      this.onprogress();
    });
  }

  onprogress() {
    if (this.dom.circle) {
      if (this.per > 0) this.dom.circle.classList.add('is-fadein');
      if (this.per > 50) this.dom.circle.classList.add('is-fifty');
    }
    this.deg = Math.floor(this.per * 3.6);
    if (this.dom.pie) this.dom.pie.style.transform = `rotateZ(${this.deg}deg)`;
    if (this.dom.bar) this.dom.bar.style.width = `${this.per}%`;
    if (log) console.log(`Preload -> ${color_progress}progress`,'->', `${this.deg}deg (${this.current}/${this.total})`);
  }

  update() {
    if (log) console.log(`Preload -> ${color_update}update`,'->', `img: ${this.isImgsLoaded}, js: ${this.isJsLoaded}`);
    if (!this.isJsLoaded || !this.isImgsLoaded) return;
    if (log) console.log(`Preload -> ${color_done}done`);
    setTimeout(()=> {
      if (this.dom.circle) this.dom.circle.classList.add('is-fadeout');
      clearInterval(this.timer);
      setTimeout(()=> { window.isPreloaded = true; }, useCircleLoading ? 600:0);
    }, useCircleLoading ? 1000: 300);
  }

}

new Preload();
