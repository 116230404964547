module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HOTARU PERSONALIZED","short_name":"HOTARU","start_url":"/","background_color":"#93a6cb","theme_color":"#93a6cb","display":"minimal-ui","icons":[{"src":"/favicons/apple-touch-icon-152x152.png","sizes":"152x152","type":"image/png"},{"src":"/favicons/apple-touch-icon-180x180.png","sizes":"180x180","type":"image/png"},{"src":"/favicons/apple-touch-icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/favicons/apple-touch-icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"xim5kii"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-119104411-3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
