/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location, prevLocation }) => {
  // const preload = import("./src/js/_preload.js");
  require("./src/js/_preload.js");
  const bundle = import("./src/js/_bundle.js");
  bundle.then((module) => {
    new module.HotaruRouter();
  })
  setAdEbisCommonTag();
  setClarityTag();
}
function setAdEbisCommonTag() {
  const tajTag = document.querySelector('script[src*="taj"]');
  if (tajTag && tajTag.parentNode) {
    tajTag.parentNode.removeChild(tajTag);
  }

  (function(a,d,e,b,i,s){ window[i]=window[i]||[];var f=function(a,d,e,b,i,s){
    var o=a.getElementsByTagName(d)[0],h=a.createElement(d),t='text/javascript';
    h.type=t;h.async=e;h.onload=function(){ window[i].init(
      { argument:s, auto:true }
    );};h._p=o;return h;},h=f(a,d,e,b,i,s),l='//taj',j=b+s+'/cmt.js';h.src=l+'1.'+j;
    h._p.parentNode.insertBefore(h,h._p);h.onerror=function(k){k=f(a,d,e,b,i,s);
      k.src=l+'2.'+j;k._p.parentNode.insertBefore(k,k._p);};
  })(document,'script',true,'ebis.ne.jp/','ebis','rVHtCJXQ');
}
function setClarityTag() {
    (function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "a1wljav3ag");
}
exports.onRouteUpdateDelayed = () => {
}
exports.onPreRouteUpdate = () => {
}